<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-layout pt-4 wrap justify-left>
      <v-flex xs12 xl11 px-2>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs5 sm7 md7 lg9 xl9>
            <v-layout wrap justify-start>
              <v-flex xs3 sm2 md2 lg1 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Inventory
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs7 sm3 md3 lg3 align-self-end text-right>
           <v-layout wrap justify-end>
            <v-flex xs12 text-right>
              <v-text-field class="searchBox" v-model="searchKey" prepend-inner-icon="mdi-magnify"
                placeholder="Search for products" solo flat hide-details color="#FF0F0F"></v-text-field>
            </v-flex>
           </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs12 xl11>
            <CategorySlider />
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="products > 0">
          <v-flex xs12 sm7 md5 lg5 xl4 text-left align-self-center>
            <v-layout wrap justify-start fill-height>
              <v-flex xs2 sm2 md2 lg2 xl2 pl-xl-4 text-left align-center>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensansbold;
                    font-size: 14px;
                  "
                >
                  Status
                </span>
              </v-flex>
              <v-flex xs10 sm10 md10 lg10 xl10 text-left align-center>
                <v-radio-group v-model="listStatus" row>
                  <v-radio
                    value="Active"
                    class="mx-2"
                    :label="'Active (' + activeCount + ')'"
                    :ripple="false"
                    color="#5DC57C"
                  ></v-radio>
                  <v-radio
                    value="Inactive"
                    class="mx-2"
                    :label="'Inactive (' + inactiveCount + ')'"
                    :ripple="false"
                    color="#5DC57C"
                  ></v-radio>
                  <v-radio
                    value="All"
                    class="mx-2"
                    :label="'All (' + totalCount + ')'"
                    :ripple="false"
                    color="#5DC57C"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="products.length > 0">
          <template v-for="(item, i) in products">
            <v-flex xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
              <InventoryItem v-bind:storage="item" @stepper="winStepper" />
            </v-flex>
            <v-flex xs12 xl11 :key="i" py-4>
              <v-divider></v-divider>
            </v-flex>
          </template>
        </v-layout>
        <v-layout wrap justify-center v-if="pages > 1">
          <v-flex xs12>
            <v-pagination
              v-model="currentPage"
              :length="pages"
              :total-visible="7"
              color="#FF0000"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="products.length < 1">
          <v-flex xs12>
            <span
              style="
                font-family: poppinsregular;
                font-size: 25px;
                color: #000000;
              "
            >
              Oops! No Products Found
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import CategorySlider from "./categorySlider";
import InventoryItem from "./inventoryItem";
export default {
  components: {
    CategorySlider,
    InventoryItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      pages: 1,
      count: 10,
      productTotalCount: 0,
      products: [],
      activeCount: 0,
      inactiveCount: 0,
      totalCount: 0,
      productData: {},
      listStatus: "All",
      searchKey: null,
    };
  },
  watch: {
    listStatus() {
      this.getData();
    },
    searchKey(){
      this.getData();
    },
    currentPage() {
      this.$router.push({
        path: "/Inventory",
        query: {
          page: this.currentPage,
        },
      });
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.getData) {
        this.getData();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          category: this.$route.query.category,
          status: this.listStatus,
          count: this.count,
          page: this.$route.query.page,
          keyword:this.searchKey
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.products = response.data.data;
            this.pages = response.data.pages;
            this.activeCount = response.data.activecount;
            this.inactiveCount = response.data.inactivecount;
            this.totalCount = response.data.totalcount;
            this.productTotalCount = response.data.totalcount;
          } else {
            this.products = [];
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
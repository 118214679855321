<template>
  <div>
    <v-sheet class="py-0 px-1">
      <v-chip-group center-active show-arrows>
        <v-chip
          :color="($route.query.category == null || $route.query.category == 'undefined' )? '#30B868' : '#1D1C1C'"
          @click="changeRoute(null)"
        >
          <span
            style="
              font-family: poppinssemibold;
              font-size: 12px;
              color: #ffffff;
              letter-spacing: 2px;
            "
          >
           All
          </span>
        </v-chip>
        <v-chip
          v-for="(item, i) in categories"
          :key="i"
          :color="$route.query.category == item._id ? '#30B868' : '#1D1C1C'"
          @click="changeRoute(item)"
        >
          <span
            style="
              font-family: poppinssemibold;
              font-size: 12px;
              color: #ffffff;
              letter-spacing: 2px;
            "
          >
            {{ item.name }}
          </span>
        </v-chip>
      </v-chip-group>
    </v-sheet>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      categories: [],
    };
  },
  beforeMount() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list/seller",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // category: this.$route.query.category,
          // styl: this.$route.query.styl,
         id:this.$store.state.userData._id
        },
      })
        .then((reponse) => {
          this.appLoading = false;
          this.categories = reponse.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    changeRoute(item) {
      this.$router.push({
        path: "/Inventory",
        query: {
          category:
          item==null?null: this.$route.query.category != item._id ? item._id : null,
          searchKey: this.$route.query.searchKey,
        },
      });
    },
  },
};
</script>
<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this product ?</span
            >
          </v-flex>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 14px;
              "
              >{{ storage.productname }}</span
            >
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn tile block depressed dark color="red" @click="deleteProduct">
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="deleteDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="promoDialog" max-width="800px">
      <v-card width="800px">
        <v-layout wrap justify-center style="background-color: white">
          <v-flex px-2 xs12 sm12 md10 lg10 xl8 text-left>
            <v-layout pt-8 wrap justify-center>
              <v-flex text-center xs12 md12 lg12>
                <span
                  style="
                    color: #000000;
                    font-family: poppinsbold;
                    font-size: 22px;
                  "
                  >Add Promocode</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 pt-8>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Promocode</span
                >
                <v-text-field
                  v-model="couponData.code"
                  placeholder="Promo Code"
                  required
                  outlined
                  dense
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Description
                </span>
                <v-text-field
                  v-model="couponData.description"
                  placeholder="Description"
                  outlined
                  dense
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Discount
                </span>
                <v-text-field
                  v-model="couponData.discount"
                  placeholder="Discount Percentage"
                  dense
                  required
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Maximum Amount To Be Applied
                </span>
                <v-text-field
                  v-model="couponData.maxAmount"
                  placeholder="Maximum Amount To Be Applied"
                  dense
                  required
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select From Date
                </span>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="couponData.startingDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.startingDate"
                      placeholder="Select From Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="couponData.startingDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(couponData.startingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select To Date
                </span>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="couponData.endingDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.endingDate"
                      placeholder="Select To Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="couponData.endingDate"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu1 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(couponData.endingDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select Starting Time
                </span>
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="couponData.startingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.startingTime"
                      placeholder="Starting Time"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu3"
                    v-model="couponData.startingTime"
                    full-width
                    @click:minute="$refs.menu3.save(couponData.startingTime)"
                  ></v-time-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Select Ending Time
                </span>
                <v-menu
                  ref="menu4"
                  v-model="menu4"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="couponData.endingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="couponData.endingTime"
                      placeholder="Ending Time"
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menu4"
                    v-model="couponData.endingTime"
                    full-width
                    @click:minute="$refs.menu4.save(couponData.endingTime)"
                  ></v-time-picker>
                </v-menu>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Maximum Order To Be Applied
                </span>
                <v-text-field
                  v-model="couponData.maximumOderTobeApplied"
                  placeholder="Maximum Order To Be Applied "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <span
                  class="pl-3"
                  style="
                    color: #000000;
                    font-family: opensansbold;
                    font-size: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                  "
                >
                  Limit Per Customer
                </span>
                <v-text-field
                  v-model="couponData.limitPerCustomer"
                  placeholder="Limit Per Customer "
                  dense
                  required
                  type="number"
                  outlined
                ></v-text-field>
                <v-layout wrap justify-end>
                  <v-flex xs12 md6 lg6 pt-4 pb-4 pa-2 text-right>
                    <v-btn
                      tile
                      block
                      dark
                      color="black"
                      depressed
                      class="text-capitalize mb-3"
                      @click.stop="promoDialog = false"
                      :ripple="false"
                      >Close</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 md6 lg6 pt-4 pb-4 text-right pa-2>
                    <v-btn
                      tile
                      block
                      dark
                      color="#30B868"
                      depressed
                      class="text-capitalize mb-3"
                      @click.prevent="addCode()"
                      :ripple="false"
                      >Submit</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap py-2>
      <v-flex xs12 sm12 md12 lg6>
        <v-layout wrap justify-start>
          <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
            <v-img
              :src="mediaUURL + storage.photos[0]"
              aspect-ratio="1"
              contain
              height="100px"
            ></v-img>
          </v-flex>
          <v-flex xs12 sm9 md9 lg9 xl9>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex lg12 text-left>
                    <router-link :to="'/Product/' + storage._id">
                      <span
                        style="
                          font-family: opensanslight;
                          font-size: 16px;
                          color: #000000;
                        "
                      >
                        {{ storage.productname }}
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-end>
                <v-layout
                  wrap
                  justify-start
                  v-for="(item, i) in storage.size"
                  :key="i"
                >
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap v-if="item.size">
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Variant
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          {{ item.size }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm2 md2 lg2 xl2>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                          >{{ item.price }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm2 md2 lg2 xl2>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Offer Price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                          >{{ item.offerPrice }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs6
                    sm3
                    md3
                    lg3
                    xl3
                    v-for="(list, l) in item.stock"
                    :key="l"
                  >
                    <v-layout wrap>
                      <v-flex xs6>
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <span
                              style="
                                color: #8d8d8d;
                                font-family: opensanslight;
                                font-size: 14px;
                              "
                              >Stock</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <span
                              style="
                                font-family: opensansbold;
                                color: black;
                                font-size: 20px;
                              "
                              >{{ list.stock }}</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 v-if="list.color">
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <span
                              style="
                                color: #8d8d8d;
                                font-family: opensanslight;
                                font-size: 14px;
                              "
                              >Colour</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex text-left lg12>
                            <v-card
                              outlined
                              rounded="30px"
                              class="colorselector"
                              width="30px"
                              :color="list.color ? '#ffebeb' : ''"
                            >
                              <v-layout py-2 wrap align-content-center>
                                <v-flex xs12 text-center>
                                  <v-avatar :color="list.color" size="15" />
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm2 md2 lg2 xl2 text-right>
                    <v-switch
                      :disabled="item.status == 'Pending' ? true : false"
                      v-model="item.status"
                      true-value="Active"
                      false-value="Inactive"
                      color="#5DC57C"
                      hide-details
                      :ripple="false"
                      class="statusKey"
                      :loading="statusLoading"
                      @click="changeStockStatus(item)"
                    >
                      <template slot="placeholder">
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                        >
                          {{ item.status }}
                        </span>
                      </template>
                    </v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-left xs1 text-center hidden-md-and-down>
            <v-divider vertical></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex text-left xs12 md12 lg6>
        <v-layout wrap justify-space-between fill-height>
          <v-flex xs12 sm2 md2 lg2 xl2>
            <v-layout wrap>
              <v-flex xs6 sm12>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  Item added on
                </span>
              </v-flex>
              <v-flex xs6 sm12>
                <span
                  style="
                    font-family: opensanssemibold;
                    font-size: 15px;
                    color: #000000;
                  "
                >
                  {{ formatDate(storage.created_at).slice(0, 11) }}
                  <br />
                  {{ formatDate(storage.created_at).slice(11, 20) }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm2 md2 lg2 xl2>
            <v-layout wrap>
              <v-flex xs6 sm12>
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  Available Stock
                </span>
              </v-flex>
              <v-flex xs6 sm12 text-left align-self-start>
                <span style="font-family: opensansbold; font-size: 20px">
                  {{ storage.stockquantity }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm2 md2 lg2 xl2>
            <v-layout wrap>
              <v-flex xs6 sm12 align-self-center v-if="storage.hsnCode">
                <span
                  style="
                    color: #8d8d8d;
                    font-family: opensanslight;
                    font-size: 14px;
                  "
                >
                  HSN Code
                </span>
              </v-flex>
              <v-flex
                xs6
                sm12
                text-left
                align-self-start
                v-if="storage.hsnCode"
              >
                <span style="font-family: opensansbold; font-size: 20px">
                  {{ storage.hsnCode }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 md4>
            <v-layout wrap justify-end>
              <v-flex xs12 xl12 align-self-end>
                <v-layout wrap justify-end>
                  <v-flex xs5 sm5 md5 lg5 xl5>
                    <v-btn
                      tile
                      block
                      small
                      outlined
                      color="warning"
                      style="text-transform: none"
                      :to="'Product/Edit/' + storage._id"
                    >
                      <span
                        style="
                          color: #000;
                          font-family: poppinsregular;
                          font-size: 14px;
                        "
                      >
                        <v-icon left>mdi-pencil</v-icon>
                        Edit
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs1>
                    <v-icon @click="deleteDialogue = true">mdi-delete</v-icon>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm2 md1 lg1 xl1 text-left pr-2>
            <v-layout wrap justify-end fill-height>
              <v-flex xs6 text-right>
                <v-switch
                  :disabled="storage.status == 'Pending' ? true : false"
                  v-model="storage.status"
                  true-value="Active"
                  false-value="Inactive"
                  color="#5DC57C"
                  hide-details
                  :ripple="false"
                  class="statusKey"
                  :loading="statusLoading"
                  @click="changeProductStatus(storage)"
                >
                  <template slot="placeholder">
                    <span
                      style="
                        color: #000000;
                        font-family: opensanssemibold;
                        font-size: 16px;
                      "
                    >
                      {{ storage.status }}
                    </span>
                  </template>
                </v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-space-between>
              <v-flex xs12 sm2>
                <v-layout wrap justify-start>
                  <v-flex xs6 text-left v-if="!storage.promoCodeData">
                    <v-btn
                      tile
                      depressed
                      outlined
                      color="warning"
                      small
                      style="text-transform: none"
                      @click="dialogOpen(storage)"
                    >
                      <span
                        style="
                          color: #000;
                          font-family: poppinsregular;
                          font-size: 13px;
                        "
                      >
                        Add Promocode
                      </span>
                    </v-btn></v-flex
                  >
                  <v-flex xs6 text-center v-if="storage.promoCodeData">
                    <v-layout wrap justify-start>
                      <v-flex xs12 text-left>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Promocode
                        </span>
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          <span v-if="storage.promoCodeData"
                            >{{ storage.promoCodeData.code }}
                          </span>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3 text-center v-if="storage.promoCodeData">
                <v-layout wrap justify-start>
                  <v-flex xs12 text-left>
                    <span
                      style="
                        color: #8d8d8d;
                        font-family: opensanslight;
                        font-size: 14px;
                      "
                    >
                      Promocode Status
                    </span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <v-switch
                      :disabled="
                        storage.promoCodeData.status == 'Pending' ? true : false
                      "
                      v-model="storage.promoCodeData.status"
                      true-value="Active"
                      false-value="Inactive"
                      color="#5DC57C"
                      hide-details
                      :ripple="false"
                      class="statusKey"
                      :loading="statusLoading"
                      @click="changePromoStatus(storage.promoCodeData)"
                    >
                      <template slot="placeholder">
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                        >
                          {{ storage.promoCodeData.status }}
                        </span>
                      </template>
                    </v-switch>
                    <!-- <span
                    style="
                      font-family: opensansbold;
                      color: #3bc17a;
                      font-size: 14px;
                    "
                  >
                    <span v-if="storage.promoCodeData.status=='Active'"> Approved </span>
                    <span v-else style="color: #ff1313"> Pending </span>
                  </span> -->
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm3>
                <v-layout wrap justify-start v-if="storage.promoCodeData">
                  <v-btn
                    tile
                    depressed
                    outlined
                    color="warning"
                    small
                    style="text-transform: none"
                    :to="'Product/editPromocode/' + storage.promoCodeData._id"
                  >
                    <span
                      style="
                        color: #000;
                        font-family: poppinsregular;
                        font-size: 13px;
                      "
                    >
                      Edit Promocode
                    </span>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
      promoDialog: false,
      ItemProduct: "",
      menu1: false,
      menu: false,
      menu3: false,
      menu4: false,
      couponData: {
        discount: null,
        code: null,
        description: "",
        startingDate: new Date().toISOString().substr(0, 10),
        endingDate: new Date().toISOString().substr(0, 10),
        startingTime: "",
        endingTime: "",
        maxAmount: "",
        maximumOderTobeApplied: "",
        limitPerCustomer: "",
      },
    };
  },
  methods: {
    changeProductStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/product/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Product Status Changed to " + item.status;
            this.showSnackBar = true;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeStockStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/productStock/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Product Status Changed to " + item.status;
            this.showSnackBar = true;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changePromoStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/promoCode/changeStatus/seller",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Promocode Status Changed to " + item.status;
            this.showSnackBar = true;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogOpen(item) {
      this.promoDialog = true;
      this.ItemProduct = item;
    },
    deleteProduct() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/product/delete",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              getData: true,
            });
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addCode() {
      this.couponData.productId = this.ItemProduct._id;
      axios({
        url: "/promoCode/add/seller",
        method: "POST",
        data: this.couponData,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$emit("stepper", {
              getData: true,
            });
            this.promoDialog = false;
            this.msg = "Added Sucessfully";
            this.showSnackBar = true;
            this.couponData = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>

<style>
div.vertical-line {
  width: 1px; /* Line width */
  background-color: #8d8d8d; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left; /* Causes the line to float to left of content. 
        You can instead use position:absolute or display:inline-block
        if this fits better with your design */
}
.statusKey.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.colorselector.v-sheet.v-card {
  border-radius: 35px;
}
</style>
